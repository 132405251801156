import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { db } from '../firebase';
import { collection, getDocs, addDoc, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Request.css';

const Request = ({ query: propQuery }) => {
    const [requests, setRequests] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [newRequest, setNewRequest] = useState({
        department: '',
        details: ''
    });
    const [loading, setLoading] = useState(true);
    const [userCompany, setUserCompany] = useState('');

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const requestsQuery = query(
                    collection(db, 'Requests'),
                    where('query_id', '==', propQuery.queryId)
                );
                const querySnapshot = await getDocs(requestsQuery);
                const fetchedRequests = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setRequests(fetchedRequests);
            } catch (error) {
                console.error('Error fetching requests:', error);
            } finally {
                setLoading(false);
            }
        };

        if (propQuery?.queryId) {
            fetchRequests();
        }
    }, [propQuery]);

    useEffect(() => {
        const fetchUserCompany = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;

            if (currentUser) {
                const userEmail = currentUser.email;
                const usersQuery = query(collection(db, 'users'), where('email', '==', userEmail));
                const userSnapshot = await getDocs(usersQuery);
                if (!userSnapshot.empty) {
                    const userData = userSnapshot.docs[0].data();
                    setUserCompany(userData.company);
                } else {
                    console.warn('User not found in Users collection');
                }
            }
        };

        fetchUserCompany();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewRequest({ ...newRequest, [name]: value });
    };

    const handleSubmit = async () => {
        if (newRequest.department && newRequest.details) {
            setLoading(true);
            try {
                const newRequestData = {
                    query_id: propQuery.queryId,
                    request_details: newRequest.details,
                    status: 'Pending',
                    date_created: new Date().toISOString(),
                    department: newRequest.department,
                    company: userCompany
                };
                const docRef = await addDoc(collection(db, 'Requests'), newRequestData);
                setRequests(prevRequests => [
                    ...prevRequests,
                    { id: docRef.id, ...newRequestData }
                ]);
                setShowForm(false);
                setNewRequest({ department: '', details: '' });
            } catch (error) {
                console.error('Error adding request:', error);
            } finally {
                setLoading(false);
            }
        } else {
            alert('Please fill in all fields.');
        }
    };

    const renderTable = () => {
        if (loading) {
            return <div>Loading...</div>;
        }

        if (requests.length === 0) {
            return <p>No requests are there for this Query ID.</p>;
        }

        return (
            <table className="request-table">
                <thead>
                    <tr>
                        <th>Request ID</th>
                        <th>Department</th>
                        <th>Status</th>
                        <th>Raised Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {requests.map((request) => (
                        <tr key={request.id}>
                            <td>{request.id}</td>
                            <td>{request.department}</td>
                            <td>{request.status}</td>
                            <td>{new Date(request.date_created).toLocaleDateString()}</td>
                            <td>
                                {/* Link to request details page */}
                                <Link to={`/request/${request.id}`}>
                                    <button className="view-button">View</button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="request-view">
            <h2>Requests for Query ID: {propQuery.queryId}</h2>

            <div className="header-container">
                <button className="create-request-button" onClick={() => setShowForm(true)}>+ Create Request</button>
            </div>

            {showForm && (
                <div className="request-form">
                    <h3>Create a New Request</h3>
                    <label>
                        Department:
                        <select name="department" value={newRequest.department} onChange={handleChange}>
                            <option value="">Select a department</option>
                            <option value="Clearance">Clearance</option>
                            <option value="International Operations">International Operations</option>
                            <option value="Transportation">Transportation</option>
                        </select>
                    </label>
                    <label>
                        Request Details:
                        <textarea 
                            name="details" 
                            value={newRequest.details} 
                            onChange={handleChange} 
                            placeholder="Enter request details here..." 
                        />
                    </label>
                    <button onClick={handleSubmit} className="submit-request-button">
                        Submit Request
                    </button>
                    <button onClick={() => setShowForm(false)} className="cancel-button">Cancel</button>
                </div>
            )}

            {renderTable()}
        </div>
    );
};

Request.propTypes = {
    query: PropTypes.shape({
        queryId: PropTypes.string,
        requests: PropTypes.array,
        fields: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string
        }))
    })
};

export default Request;
