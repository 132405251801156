// src/firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import for authentication
import { getFirestore } from "firebase/firestore"; // Import for Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBsBbXh_HmRWkJrYVArmlRBsSjwur45MvQ",
  authDomain: "query2quotation.firebaseapp.com",
  projectId: "query2quotation",
  storageBucket: "query2quotation.appspot.com",
  messagingSenderId: "95551944173",
  appId: "1:95551944173:web:d0626d4fef6ab08068e3e4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Firestore
const auth = getAuth(app);
const db = getFirestore(app); // Firestore initialization

export { auth, db }; // Export both auth and db
